import axios from 'axios';
import React, {useState, useEffect, useRef} from 'react';
import {Card} from 'primereact/card';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {Calendar} from 'primereact/calendar';
import {Message} from 'primereact/message';
import {collection, addDoc} from 'firebase/firestore';
import {ref, uploadBytes, getDownloadURL} from 'firebase/storage';
import {db, storage} from '../firebase';
import {Link} from 'react-router-dom';
import {Dropdown} from 'primereact/dropdown';
import './MeetingScheduleForm.css';

const MeetingScheduleForm = () => {
  const [formData, setFormData] = useState({
    storeName: '',
    storeAddress: '',
    nameAndPosition: '',
    workEmail: '',
    phone: '',
    storeWebsite: '',
    expectedCalls: '',
    menuLink: '',
    hasOwnDrivers: null,
    setupTime: null,
    additionalDetails: '',
    posSystem: '',
    posSetup: '',
    driverCount: '',
    aiAgentCount: '',
    phoneCarrier: '',
    otherCarrier: '',
    activePhoneLines: '',
    availableDays: [],
    availableTimeSlots: [],
    additionalNotes: '',
    locationCount: ''
  });

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filePreviewUrls, setFilePreviewUrls] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const driverOptions = [
    {label: 'Yes', value: true},
    {label: 'No', value: false}
  ];

  const yesNoOptions = [
    {label: 'Yes', value: true},
    {label: 'No', value: false}
  ];

  const driverCountOptions = [
    {label: '1-2 drivers', value: '1-2'},
    {label: '3-5 drivers', value: '3-5'},
    {label: '6+ drivers', value: '6+'}
  ];

  const posOptions = [
    {label: 'Square', value: 'Square'},
    {label: 'Point of Success', value: 'Point of Success'},
    {label: 'Toast', value: 'Toast'},
    {label: 'Stripe', value: 'Stripe'},
    {label: 'Hunger Rush', value: 'Hunger Rush'},
    {label: 'Slice', value: 'Slice'},
    {label: 'Clover', value: 'Clover'},
    {label: 'Other', value: 'Other'}
  ];

  const aiAgentOptions = [
    {label: '5 agents', value: '5'},
    {label: '10 agents', value: '10'},
    {label: '10+ agents', value: '10+'}
  ];

  const phoneCarrierOptions = [
    {label: 'Spectrum', value: 'Spectrum'},
    {label: 'T-Mobile', value: 'T-Mobile'},
    {label: 'AT&T', value: 'AT&T'},
    {label: 'Other', value: 'Other'}
  ];

  const activePhoneLinesOptions = [
    {label: '1 line', value: '1'},
    {label: '2 lines', value: '2'},
    {label: '3+ lines', value: '3+'}
  ];

  const callVolumeOptions = [
    {label: 'Less than 50', value: 'below_50'},
    {label: '50 to 100', value: '50_100'},
    {label: 'Over 100', value: 'over_100'}
  ];

  const timeSlots = [
    {label: '6 AM - 9 AM', value: '6-9'},
    {label: '9 AM - 12 PM', value: '9-12'},
    {label: '12 PM - 3 PM', value: '12-15'},
    {label: '3 PM - 6 PM', value: '15-18'}
  ];

  const availabilityDays = [
    {label: 'Mon', value: 'monday'},
    {label: 'Tue', value: 'tuesday'},
    {label: 'Wed', value: 'wednesday'},
    {label: 'Thu', value: 'thursday'},
    {label: 'Fri', value: 'friday'},
    {label: 'Sat', value: 'saturday'}
  ];

  const locationCountOptions = [
    {label: '1 location', value: '1'},
    {label: '2-5 locations', value: '2-5'},
    {label: '6-20 locations', value: '6-20'},
    {label: '20+ locations', value: '20+'}
  ];

  const handleInputChange = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.value
    });
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    try {
      const files = e.dataTransfer.files;
      if (files && files.length > 0) {
        handleFileUpload(files);
      }
    } catch (error) {
      console.error('Error in drop handler:', error);
    }
  };

  const handleFileInputChange = (e) => {
    try {
      const files = e.target.files;
      if (files && files.length > 0) {
        handleFileUpload(files);
      }
    } catch (error) {
      console.error('Error in file input handler:', error);
    }
  };

  const handleFileUpload = (files) => {
    try {
      const newFiles = Array.from(files).filter(file =>
        file.type.startsWith('image/') || file.type === 'application/pdf'
      );

      if (newFiles.length === 0) {
        console.log('No valid files found');
        return;
      }

      // Create new arrays instead of modifying state directly
      const newUploadedFiles = [...uploadedFiles];
      const newPreviewUrls = [...filePreviewUrls];

      newFiles.forEach(file => {
        newUploadedFiles.push(file);
        if (file.type.startsWith('image/')) {
          const reader = new FileReader();
          reader.onload = () => {
            newPreviewUrls.push(reader.result);
            // Update state after all processing is done
            setFilePreviewUrls([...newPreviewUrls]);
          };
          reader.readAsDataURL(file);
        } else {
          newPreviewUrls.push(null);
        }
      });

      // Update uploaded files immediately
      setUploadedFiles(newUploadedFiles);

    } catch (error) {
      console.error('Error handling files:', error);
    }
  };

  const removeFile = (index) => {
    setUploadedFiles(prev => prev.filter((_, i) => i !== index));
    setFilePreviewUrls(prev => prev.filter((_, i) => i !== index));
  };

  const uploadFilesToStorage = async (files) => {
    console.log('Starting file upload to Firebase Storage...');
    const uploadPromises = [];
    const fileUrls = [];

    try {
      for (const file of files) {
        const fileName = `${Date.now()}-${file.name}`; // Add timestamp to prevent name conflicts
        const storageRef = ref(storage, `menus/${formData.storeName}/${fileName}`);
        console.log(`Uploading file: ${fileName}`);

        const uploadPromise = uploadBytes(storageRef, file)
          .then(snapshot => {
            console.log(`Uploaded ${fileName} successfully`);
            return getDownloadURL(snapshot.ref);
          })
          .then(url => {
            console.log(`Got download URL for ${fileName}`);
            fileUrls.push({
              name: file.name,
              type: file.type,
              size: file.size,
              url: url,
              uploadedAt: new Date().toISOString()
            });
          })
          .catch(error => {
            console.error(`Error uploading ${fileName}:`, error);
            throw error;
          });

        uploadPromises.push(uploadPromise);
      }

      await Promise.all(uploadPromises);
      console.log('All files uploaded successfully:', fileUrls);
      return fileUrls;
    } catch (error) {
      console.error('Error in uploadFilesToStorage:', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      console.log('Starting form submission...');

      // Eğer dosya yükleme varsa, önce onları yükleyin
      let menuUrls = [];
      if (uploadedFiles.length > 0) {
        console.log('Uploading files to Firebase Storage...');
        menuUrls = await uploadFilesToStorage(uploadedFiles);
        console.log('Files uploaded successfully:', menuUrls);
      }

      // Firestore'a gönderilecek veriyi hazırla
      const submissionData = {
        ...formData,
        menuFiles: menuUrls || [],
        submittedAt: new Date(),
        status: 'pending'
      };
      console.log('Submission data prepared:', submissionData);

      // Firestore'a ekleme yap
      const docRef = await addDoc(collection(db, 'inquiries'), submissionData);

      const emailContent = `Pizza Store/Restaurant Inquiry Details:
Document ID: ${docRef.id}

Store Name: ${formData.storeName}
Store Address: ${formData.storeAddress}
Your Name and Position: ${formData.nameAndPosition}
Work Email: ${formData.workEmail}
Phone: ${formData.phone}
Store Website: ${formData.storeWebsite}
Expected Calls: ${formData.expectedCalls}
POS System: ${formData.posSystem}
POS Setup: ${formData.posSetup}
AI Agent Count: ${formData.aiAgentCount}
Phone Carrier: ${formData.phoneCarrier}${formData.otherCarrier ? ' - ' + formData.otherCarrier : ''}
Active Phone Lines: ${formData.activePhoneLines}
Has Own Drivers: ${formData.hasOwnDrivers ? 'Yes' : 'No'}${formData.hasOwnDrivers ? ` (Driver Count: ${formData.driverCount})` : ''}
Location Count: ${formData.locationCount}
Menu Link: ${formData.menuLink}
Menu Files: ${menuUrls && menuUrls.length > 0 ? menuUrls.map(file => file.url).join(', ') : 'None'}
Additional Details: ${formData.additionalDetails}
Setup Time: ${formData.setupTime ? formData.setupTime : 'N/A'}
Available Days: ${formData.availableDays.join(', ')}
Available Time Slots: ${formData.availableTimeSlots.join(', ')}
Additional Notes: ${formData.additionalNotes}
`;


      // send-email endpoint'ine POST isteği gönder
      axios
        .post('https://api.aibunny.tech/router/send-email', {
          content: emailContent,
          mailFormat: 'html'
        })
        .then((response) => {
          console.log('Email sent successfully:', response.data);
        })
        .catch((error) => {
          console.error('Error sending email:', error);
        });

      setSubmitted(true);
      setLoading(false);

      // Formu resetle
      setFormData({
        storeName: '',
        storeAddress: '',
        nameAndPosition: '',
        workEmail: '',
        phone: '',
        storeWebsite: '',
        expectedCalls: '',
        menuLink: '',
        hasOwnDrivers: null,
        setupTime: null,
        additionalDetails: '',
        posSystem: '',
        posSetup: '',
        driverCount: '',
        aiAgentCount: '',
        phoneCarrier: '',
        otherCarrier: '',
        activePhoneLines: '',
        availableDays: [],
        availableTimeSlots: [],
        additionalNotes: '',
        locationCount: ''
      });
      setUploadedFiles([]);
      setFilePreviewUrls([]);

    } catch (err) {
      console.error('Error submitting form:', err);
      setError('There was an error submitting your request. Please try again. Error: ' + err.message);
      setLoading(false);
    }
  };

  return (
    <div className="meeting-form-container">
      <div className="logo-section">
        <Link to="/" className="logo-link">
          <img src="/assets/img/logo.png" alt="AI Bunny Logo" className="company-logo"/>
        </Link>
      </div>

      <div className="form-wrapper">
        <div className="content-grid">
          <Card className="form-card">
            <div className="form-header">
              <h2>Pizza Store/Restaurant Inquiry</h2>
              <p className="subtitle">Complete this 1-minute form to claim your $100 credit and get started.</p>
            </div>

            <form onSubmit={handleSubmit} className="p-fluid">
              <div className="form-section">
                <div className="form-field">
                  <label>Pizza Store/Restaurant Name</label>
                  <InputText
                    value={formData.storeName}
                    onChange={(e) => handleInputChange(e, 'storeName')}
                    required
                    className="custom-input"
                  />
                </div>

                <div className="form-field">
                  <label>Store Address</label>
                  <InputTextarea
                    value={formData.storeAddress}
                    onChange={(e) => handleInputChange(e, 'storeAddress')}
                    placeholder="Enter your store's complete address"
                    className="custom-textarea"
                    rows={2}
                  />
                </div>

                <div className="form-field">
                  <label>Your Name and Position</label>
                  <InputText
                    value={formData.nameAndPosition}
                    onChange={(e) => handleInputChange(e, 'nameAndPosition')}
                    required
                    className="custom-input"
                  />
                </div>

                <div className="form-field">
                  <label>Work Email</label>
                  <InputText
                    value={formData.workEmail}
                    onChange={(e) => handleInputChange(e, 'workEmail')}
                    type="email"
                    required
                    className="custom-input"
                  />
                </div>

                <div className="form-field">
                  <label>Phone Number</label>
                  <InputText
                    value={formData.phone}
                    onChange={(e) => handleInputChange(e, 'phone')}
                    className="custom-input"
                    keyfilter="num"
                  />
                </div>

                <div className="form-field">
                  <label>Store Website</label>
                  <InputText
                    value={formData.storeWebsite}
                    onChange={(e) => handleInputChange(e, 'storeWebsite')}
                    className="custom-input"
                  />
                </div>

                <div className="form-field">
                  <label>Estimated Daily Phone Calls</label>
                  <SelectButton
                    value={formData.expectedCalls}
                    options={callVolumeOptions}
                    onChange={(e) => handleInputChange(e, 'expectedCalls')}
                    className="custom-select-button three-options"
                  />
                </div>

                <div className="form-field">
                  <label>Current POS System</label>
                  <Dropdown
                    value={formData.posSystem}
                    options={posOptions}
                    onChange={(e) => handleInputChange(e, 'posSystem')}
                    placeholder="Select your POS system"
                    className="custom-dropdown"
                  />
                </div>

                <div className="form-field">
                  <label>POS Setup Details</label>
                  <InputTextarea
                    value={formData.posSetup}
                    onChange={(e) => handleInputChange(e, 'posSetup')}
                    placeholder="If you use multiple POS systems, please explain your setup"
                    rows={3}
                    className="custom-textarea"
                  />
                </div>

                <div className="form-field">
                  <label>How many concurrent AI agents would you like handling calls?</label>
                  <SelectButton
                    value={formData.aiAgentCount}
                    options={aiAgentOptions}
                    onChange={(e) => handleInputChange(e, 'aiAgentCount')}
                    className="custom-select-button three-options"
                  />
                </div>

                <div className="form-field">
                  <label>Who is your current phone service provider?</label>
                  <SelectButton
                    value={formData.phoneCarrier}
                    options={phoneCarrierOptions}
                    onChange={(e) => {
                      handleInputChange(e, 'phoneCarrier');
                      if (e.value !== 'Other') {
                        setFormData(prev => ({...prev, otherCarrier: ''}));
                      }
                    }}
                    className="custom-select-button four-options"
                  />
                  {formData.phoneCarrier === 'Other' && (
                    <div className="other-carrier-input">
                      <InputText
                        value={formData.otherCarrier}
                        onChange={(e) => handleInputChange(e, 'otherCarrier')}
                        placeholder="Please specify your carrier"
                      />
                    </div>
                  )}
                </div>

                <div className="form-field">
                  <label>How many active phone lines does your store have?</label>
                  <SelectButton
                    value={formData.activePhoneLines}
                    options={activePhoneLinesOptions}
                    onChange={(e) => handleInputChange(e, 'activePhoneLines')}
                    className="custom-select-button three-options"
                  />
                </div>

                <div className="form-field">
                  <label>Do you do delivery using your own drivers?</label>
                  <SelectButton
                    value={formData.hasOwnDrivers}
                    options={yesNoOptions}
                    onChange={(e) => {
                      handleInputChange(e, 'hasOwnDrivers');
                      if (!e.value) {
                        setFormData(prev => ({...prev, driverCount: ''}));
                      }
                    }}
                    className="custom-select-button two-options"
                  />
                </div>

                {formData.hasOwnDrivers && (
                  <div className="form-field">
                    <label>What is your maximum number of drivers per day?</label>
                    <SelectButton
                      value={formData.driverCount}
                      options={driverCountOptions}
                      onChange={(e) => handleInputChange(e, 'driverCount')}
                      className="custom-select-button three-options"
                    />
                  </div>
                )}

                <div className="form-field">
                  <label>How many locations do you have?</label>
                  <div className="p-inputgroup">
                    <SelectButton
                      value={formData.locationCount}
                      options={locationCountOptions}
                      onChange={(e) => handleInputChange(e, 'locationCount')}
                      className="custom-select-button"
                    />
                  </div>
                </div>

                <div className="form-field">
                  <label>Menu Upload</label>
                  <small className="text-gray-500 block mb-2">
                    Upload your menu files (PDF, PNG, JPEG) or provide a link below
                  </small>
                  <div
                    className={`drag-drop-zone ${isDragging ? 'dragging' : ''}`}
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    onClick={() => fileInputRef.current?.click()}
                  >
                    <input
                      ref={fileInputRef}
                      type="file"
                      multiple
                      accept="image/*,application/pdf"
                      onChange={handleFileInputChange}
                      style={{display: 'none'}}
                    />
                    <div className="drop-zone-content">
                      <i className="pi pi-upload icon"></i>
                      <p>Drag & drop menu files here or click to browse</p>
                    </div>
                  </div>

                  {uploadedFiles.length > 0 && (
                    <div className="file-list">
                      {uploadedFiles.map((file, index) => (
                        <div key={index} className="file-item">
                          <span className="file-name">{file.name}</span>
                          <span className="file-size">({(file.size / 1024).toFixed(2)} KB)</span>
                          {filePreviewUrls[index] && (
                            <img
                              src={filePreviewUrls[index]}
                              alt={`Preview ${index}`}
                              style={{width: '40px', height: '40px', objectFit: 'cover'}}
                            />
                          )}
                          <Button
                            icon="pi pi-times"
                            className="p-button-rounded p-button-danger p-button-text"
                            onClick={() => removeFile(index)}
                          />
                        </div>
                      ))}
                    </div>
                  )}

                  <div className="mt-4">
                    <small className="text-gray-500 block mb-2">
                      Or provide a link to your menu (from UberEats, GrubHub, your website, etc.)
                    </small>
                    <InputText
                      value={formData.menuLink}
                      onChange={(e) => handleInputChange(e, 'menuLink')}
                      placeholder="Enter menu URL"
                      className="custom-input"
                    />
                  </div>
                </div>

                <div className="form-field">
                  <label>Additional Menu/Store Details</label>
                  <small className="text-gray-500 block mb-2">
                    Please add any special details about your pizza store (e.g., "We do half and half pizza at same prices,
                    we don't sell pork, delivery charge is $1.99 until 4 miles, $2.99 until 8 miles, etc.")
                  </small>
                  <InputTextarea
                    value={formData.additionalDetails}
                    onChange={(e) => handleInputChange(e, 'additionalDetails')}
                    rows={4}
                    autoResize
                    className="custom-textarea"
                    placeholder="Enter any special details about your menu/store that you want your AI Agent to be aware of..."
                  />
                </div>

                <div className="form-field">
                  <label>Available Days and Times for Setup</label>
                  <small className="text-gray-500 block mb-2">
                    To discuss final details and complete your setup, we'll need a video call. Please select all days and times that work for you.
                  </small>

                  <div className="availability-section">
                    <div className="mb-3">
                      <label className="block mb-2">Available Days</label>
                      <SelectButton
                        value={formData.availableDays}
                        options={availabilityDays}
                        onChange={(e) => handleInputChange(e, 'availableDays')}
                        multiple
                        className="custom-select-button days-select"
                      />
                    </div>

                    <div className="time-selection">
                      <label className="block mb-2">Available Times</label>
                      <SelectButton
                        value={formData.availableTimeSlots}
                        options={timeSlots}
                        onChange={(e) => handleInputChange(e, 'availableTimeSlots')}
                        multiple
                        className="custom-select-button time-select"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-field">
                  <label>Do you have any additional notes for us?</label>
                  <InputTextarea
                    value={formData.additionalNotes}
                    onChange={(e) => handleInputChange(e, 'additionalNotes')}
                    rows={3}
                    autoResize
                    className="w-full"
                    placeholder="Share any additional information or specific requirements..."
                  />
                </div>

              </div>

              <div className="form-actions">
                <Button
                  type="submit"
                  label="Submit Inquiry"
                  className="submit-button"
                  icon="pi pi-check"
                  loading={loading}
                  disabled={loading}
                />
              </div>

              {error && (
                <Message
                  severity="error"
                  text={error}
                  className="mt-4 error-message"
                />
              )}

              {submitted && !error && (
                <Message
                  severity="success"
                  text="Thank you for your inquiry. We will contact you shortly!"
                  className="mt-4 success-message"
                />
              )}
            </form>
          </Card>

          <div className="welcome-section">
            <div className="try-it-section">
              <h3>Want to see it in action before applying?</h3>
              <p className="try-it-text">
                Call our demo pizza store, "Trial Pizza", at <a href="tel:2094881905"
                                                                className="phone-link">(209) 488-1905</a> and place a test order.
                Try ordering half & half pizzas or add special instructions to test the AI. After your call, you'll get SMS access
                to view your order ticket, call recording, and full dashboard in the user panel.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingScheduleForm;
