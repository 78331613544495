import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import chatBodyBackground from './../../assets/chat-body-background.png';
import seenImage from './../../assets/seen.png';
import robotImage from '../../assets/robot.jpg';
import robotLogo from './../../assets/robot2.png';
import './../../css/chatbot.css';

function linkify(text) {
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');
}

const ChatBot = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isChatting, setIsChatting] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const chatBodyRef = useRef(null);

    useEffect(() => {
        loadMessages();
    }, []);

    useEffect(() => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
    }, [messages, isOpen]);

    const toggleChatBot = () => {
        setIsOpen(!isOpen);
    };

    const startChat = () => {
        if (name && phone) {
            setIsChatting(true);
        }
    };

    const handleSendMessage = async () => {
        if (inputMessage.trim() !== '') {
            const timestamp = new Date();
            const customerMessage = inputMessage;
            const linkedMessage = linkify(inputMessage);
            const newMessage = {
                customer_message: customerMessage,
                response_message: '<div class="loading-dots"><div class="dot"></div><div class="dot"></div><div class="dot"></div></div>',
                timestamp
            };
            setMessages([...messages, newMessage]);
            setInputMessage('');

            try {
                const response = await axios.post('https://api.aibunny.tech/aibunnywebsite', {
                    name,
                    phone_number: phone,
                    message: customerMessage
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                const systemMessage = {
                    customer_message: customerMessage,
                    response_message: linkify(response.data.message),
                    timestamp: new Date()
                };
                setMessages(prevMessages => {
                    const updatedMessages = [...prevMessages];
                    updatedMessages[updatedMessages.length - 1] = systemMessage;
                    return updatedMessages;
                });
            } catch (error) {
                const errorMessage = {
                    customer_message: customerMessage,
                    response_message: 'Error: Network Error',
                    timestamp: new Date()
                };
                setMessages(prevMessages => {
                    const updatedMessages = [...prevMessages];
                    updatedMessages[updatedMessages.length - 1] = errorMessage;
                    return updatedMessages;
                });
            }
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage();
        }
    };

    const loadMessages = () => {
        // Mesajları yükleme işlemini burada gerçekleştirin.
        const storedMessages = [
            // Örnek mesajlar
        ];

        setMessages(storedMessages);
    };

    const renderMessages = () => {
        return messages.map((message, index) => {
            const date = new Date(message.timestamp);
            const timeString = date.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            });

            return (
                <div key={index}>
                    {message.customer_message && (
                        <div className="customer_message_container">
                            <div className="message customer_message">
                                {message.customer_message}
                            </div>
                            <div className="chat-time">
                                {timeString}
                                <img src={seenImage} className="seen-image" alt="Seen"/>
                            </div>
                        </div>
                    )}
                    {message.response_message && (
                        <div className="response_message_container">
                            <img src={robotImage} className="profile-pic" alt="Profile"/>
                            <div className="message response_message"
                                 dangerouslySetInnerHTML={{__html: message.response_message}}>
                            </div>
                        </div>
                    )}
                </div>
            );
        });
    };
    /*
          {!isOpen && (
                <div className="chat-button" onClick={toggleChatBot}>
                    Our AI Agent
                </div>
            )}
     */

    return (
        <div className="chat-container">


            {isOpen && (
                <div className="chat-window" style={{backgroundImage: `url(${chatBodyBackground})`}}>
                    <div className="chat-header">
                        <img src={robotLogo} className="chat-header-logo" alt="Chatbot Logo"/>
                        <span>Our AI Agent</span>
                        <button className="minimize-btn" onClick={toggleChatBot}>−</button>
                    </div>

                    <div className="chat-body" ref={chatBodyRef}>
                        {!isChatting ? (
                            <div className="chat-form-container">
                                <div className="chat-input-group">
                                    <label htmlFor="nameInput">Name:</label>
                                    <input
                                        type="text"
                                        id="nameInput"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Full Name"
                                    />
                                </div>
                                <div className="chat-input-group">
                                    <label htmlFor="phoneInput">Phone:</label>
                                    <input
                                        type="text"
                                        id="phoneInput"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        placeholder="Your phone number"
                                    />
                                </div>
                                
                                <button onClick={startChat}>Start</button>
                            </div>
                        ) : (
                            <div className="chat-messages">
                                {renderMessages()}
                            </div>
                        )}
                    </div>

                    {isChatting && (
                        <div className="chat-footer">
                            <input
                                type="text"
                                id="messageInput"
                                value={inputMessage}
                                onChange={(e) => setInputMessage(e.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="Type a message..."
                            />
                            <button onClick={handleSendMessage}>Send</button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ChatBot;
