import React, { Component } from 'react';
import homedata from '../../data/homedata.json';
import '../../css/srcStyle.css'

class Feature extends Component {
    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }

    render() {
        let publicUrl = process.env.PUBLIC_URL;
        let imgattr = 'image';
        return (
            <section id="feature" className="p-80px-tb position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 offset-sm-2">
                            <div className="section-title text-center m-50px-b">
                                <h2>{homedata.features.sectiontitle}</h2>
                            </div>
                            <div>
                                <strong>
                                    <span style={{ color: "black" }}>
                                        ✅ The AI Order Agent we set up for Soho Pizza on Lark St, Albany, NY has taken over 200 orders on the phone and 1000 orders on the website and WhatsApp without any human intervention.
                                        <br />
                                        ✅ It performs various actions during conversations such as address checks, creating payment links, printing tickets at the store, and scheduling orders.
                                        <br />
                                        ✅ It can handle multiple customers simultaneously in different languages if needed! (English, Spanish, Turkish, and many more)
                                        <br />
                                        ✅ It has even made funny jokes at the end of conversations.
                                        <br />
                                        🖥️ Soho Pizza staff can monitor all the conversations on an advanced web app user panel on our website. They can listen the the phone calls, see the chat history, and more.
                                    </span>
                                </strong>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 md-p-50px-b">
                            <div className="row">
                                {homedata.features.featurescontent.map((feature, i) =>
                                    <div key={i} className="col-sm-6">
                                        <div className="single-feature">
                                            <i className={feature.icon}></i>
                                            <h4>{feature.title}</h4>
                                            <p>{feature.content}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="position-relative featured-mockup">
                                <div className="bordered-box"></div>
                                <img className="phone-2" src={publicUrl + homedata.features.imgurl2} alt={imgattr} />
                            </div>
                        </div>
                    </div>
                    
                    {/* Demo Section */}
                    <div className="row mt-5">
                        <div className="col-12">
                            <div style={{
                                background: 'linear-gradient(135deg, #2563eb11 0%, #4F46E511 100%)',
                                borderRadius: '16px',
                                padding: '2rem',
                                border: '1px solid rgba(37, 99, 235, 0.1)',
                                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.05)'
                            }}>
                                <div className="row align-items-center">
                                    <div className="col-lg-8">
                                        <h3 style={{ 
                                            fontSize: '1.5rem', 
                                            fontWeight: '600',
                                            marginBottom: '1rem',
                                            color: '#1e40af'
                                        }}>
                                            Try Our AI Agent in Action
                                        </h3>
                                        <p style={{ 
                                            fontSize: '1.1rem',
                                            lineHeight: '1.7',
                                            color: '#4b5563',
                                            marginBottom: '1.5rem'
                                        }}>
                                            Experience our AI Agent firsthand! Call our demo store "Trial Pizza" at{' '}
                                            <a 
                                                href="tel:2094881905"
                                                style={{
                                                    color: '#2563eb',
                                                    fontWeight: '600',
                                                    textDecoration: 'none'
                                                }}
                                            >
                                                (209) 488-1905
                                            </a>
                                            {' '}and place a test order.
                                        </p>
                                        <div style={{
                                            display: 'flex',
                                            gap: '1.5rem',
                                            flexWrap: 'wrap'
                                        }}>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem'
                                            }}>
                                                <span style={{ color: '#2563eb' }}>🔄</span>
                                                <span>Try half & half pizzas</span>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem'
                                            }}>
                                                <span style={{ color: '#2563eb' }}>📝</span>
                                                <span>Add special instructions</span>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem'
                                            }}>
                                                <span style={{ color: '#2563eb' }}>📱</span>
                                                <span>Get SMS access to dashboard</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 text-center mt-4 mt-lg-0">
                                        <a 
                                            href="tel:2094881905"
                                            className="btn btn-default btn-filled animated-btn"
                                            style={{
                                                background: '#2563eb',
                                                borderColor: '#2563eb',
                                                padding: '0.75rem 2rem',
                                                fontSize: '1.1rem'
                                            }}
                                        >
                                            Call Demo Store Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Feature;
