import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBMJH_E8KNh3i4q2nSYwXpKP6-k9oxstgs",
    authDomain: "utility-state-440610-v9.firebaseapp.com",
    projectId: "utility-state-440610-v9",
    storageBucket: "utility-state-440610-v9.firebasestorage.app",
    messagingSenderId: "1083140758332",
    appId: "1:1083140758332:web:9cd729518c65c603a91c39"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Auth, Firestore, and Storage
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
